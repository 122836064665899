//******************************************************************************
//*                                                                            *
//* (C) 2015 OptInCall Inc.                                                    *
//*                                                                            *
//* All rights reserved. Reproduction or transmission in whole or in part, in  *
//* any form or by any means, electronic, mechanical, or otherwise, is         *
//* prohibited without the prior written permission of the copyright owner.    *
//******************************************************************************

var config = require('../../config');
var heartbeat = require('../../../lib/heartbeat.js');

var url = config.api.url + '/callbacks/console';

heartbeat.start({
    url: url,
    methods: ["warn", "error"]
});